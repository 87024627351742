import { useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Select, { components } from 'react-select';
import { Colors } from '../lnuser/LabelsList';
import Label from '../../components/Label';
import { templatesState } from '../../_states/template.states';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import CampaignMessagePreview from './CampaignMessagePreview';
import Labels from '../lnuser/Labels';
import Dropdown from '../../components/Dropdown';
import { tr } from '../../common/locale';
import { labelsState } from '../../_states/label.states';
import { campaignState } from '../../_states/campaign.states';
import utils from '../../common/utils';
import Attachments from '../../components/Attachments';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

function CampaignMessage({ getStepIndex, mess }) {
    const stepIndex = getStepIndex(`action_message_${mess}`);
    const [campaign, setCampaign] = useRecoilState(campaignState);
    const labels = useRecoilValue(labelsState);
    const contentRef = useRef();
    const templates = useRecoilValue(templatesState);
    const contentAlternativeRef = useRef();
    const messageContainJobCompanyVars = utils.hasVariables(campaign[`action_message_${mess}_content`]);
    const getStepSource = () => {
        if (mess === 1) {
            if (campaign.action_connect) {
                return campaign.action_connect_step_accepted_name;
            }
            if (campaign.action_follow) {
                return campaign.action_follow_step_name;
            }
            if (campaign.action_visit) {
                return campaign.action_visit_step_name;
            }
            return campaign.recipient_from_step_name;
        }
        return campaign[`action_message_${mess - 1}_step_sent_name`];
    };
    const messagesTemplates = templates?.filter((item) => item.type === 'message');
    const setMessage = (message) => {
        const newCamp = { ...campaign };
        newCamp[`action_message_${mess}_content`] = message;
        setCampaign(newCamp);
    };
    const setMessageAlternate = (message) => {
        const newCamp = { ...campaign };
        newCamp[`action_message_${mess}_alternate`] = message;
        setCampaign(newCamp);
    };
    const setFiles = (files) => {
        const newCamp = { ...campaign };
        newCamp[`action_message_${mess}_attachment`] = files ? JSON.stringify(files) : null;
        setCampaign(newCamp);
    };
    const setFilesAlternate = (files) => {
        const newCamp = { ...campaign };
        newCamp[`action_message_${mess}_attachment_alternate`] = files ? JSON.stringify(files) : null;
        setCampaign(newCamp);
    };
    return (
        <div className="campaign__config">
            <h1 className="title">
                {mess === 1 ? tr('Send message') : tr('Follow-up message {{number}}', { number: mess - 1 })}
                <div className="flex mbi">
                    {campaign[`action_message_${mess}`] && (
                        <span className="seq-activated">
                            <Icon white icon="check" />
                            {tr('Sequence added')}
                        </span>
                    )}
                    <CampaignMessagePreview
                        campaign={campaign}
                        message={campaign[`action_message_${mess}_content`]}
                        messageAlternate={campaign[`action_message_${mess}_alternate`]}
                    />
                </div>
            </h1>
            <div className="campaign__config-wrapper">
                {mess !== 1 && (
                    <div className={`autom ${campaign[`action_message_${mess}`] && 'activated'}`}>
                        <div className="autom-line stepped">
                            <div className="autom-desc black">{tr('If the message is ignored for')}</div>
                            <Dropdown
                                disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                                className="small"
                                btnFace="secondary"
                                iconRight
                                btnClassName="btn--bordered"
                                label={tr('{{count}} day', {
                                    count: campaign[`action_message_${mess - 1}_ignore_days`],
                                })}
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu direction="s">
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                        <Dropdown.ButtonItem
                                            key={item}
                                            btnLabel={tr('{{count}} day', { count: item })}
                                            onClick={() => {
                                                const newCamp = { ...campaign };
                                                newCamp[`action_message_${mess - 1}_ignore_days`] = item;
                                                setCampaign(newCamp);
                                            }}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                )}
                <div className={`autom ${campaign[`action_message_${mess}`] && 'activated'}`}>
                    <div className="autom-line stepped">
                        <div className="autom-step step-blue">
                            {campaign[`action_message_${mess}`] ? stepIndex : ''}
                        </div>
                        <span className="bullet" />
                        <div className="autom-desc">{tr('Send a message to the members of the column')}</div>
                        <Input disabled value={getStepSource()} />
                        {mess === 1 && (campaign.action_visit || campaign.action_follow || campaign.action_connect) && (
                            <>
                                <div>{tr('after')}</div>
                                <Dropdown
                                    className="small"
                                    btnFace="secondary"
                                    iconRight
                                    btnClassName="btn--bordered"
                                    label={tr('{{count}} day', {
                                        count: campaign.action_message_1_wait_days,
                                    })}
                                    onClick={(e) => e.stopPropagation()}
                                    onDoubleClick={(e) => e.stopPropagation()}
                                >
                                    <Dropdown.Menu direction="s">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                                            <Dropdown.ButtonItem
                                                key={item}
                                                btnLabel={tr('{{count}} day', { count: item })}
                                                onClick={() => {
                                                    const newCamp = { ...campaign };
                                                    newCamp.action_message_1_wait_days = item;
                                                    setCampaign(newCamp);
                                                }}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        )}
                    </div>

                    {messagesTemplates?.length > 0 && (
                        <div className="autom-line mb--25">
                            <Dropdown
                                disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                                className="templates-link"
                                btnFace="link-primary"
                                noIcon
                                label={tr('+ Use a template message')}
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu direction="s">
                                    {messagesTemplates?.map((element) => (
                                        <Dropdown.ButtonItem
                                            isSubscriptionPopover
                                            key={element?.id}
                                            btnLabel={element.title}
                                            onClick={() => {
                                                const newCamp = { ...campaign };
                                                newCamp[`action_message_${mess}_content`] = element.content;
                                                newCamp[`action_message_${mess}_alternate`] = element.alternative;
                                                newCamp[`action_message_${mess}_attachment`] = element.attachment;
                                                newCamp[`action_message_${mess}_attachment_alternate`] =
                                                    element.alternative_attachment;
                                                setCampaign(newCamp);
                                            }}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}

                    <div className="autom-line column">
                        {utils.variableButtons(
                            contentRef,
                            true,
                            campaign[`action_message_${mess}_content`],
                            (current) => {
                                const newCamp = { ...campaign };
                                newCamp[`action_message_${mess}_content`] = current;
                                setCampaign(newCamp);
                            }
                        )}
                        <Input
                            disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                            className="message"
                            type="textarea"
                            value={campaign[`action_message_${mess}_content`]}
                            maxlength="10000"
                            ref={contentRef}
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                        />
                        <Attachments
                            inputRef={contentRef}
                            message={campaign[`action_message_${mess}_content`]}
                            setMessage={setMessage}
                            files={
                                campaign[`action_message_${mess}_attachment`]
                                    ? JSON.parse(campaign[`action_message_${mess}_attachment`])
                                    : []
                            }
                            setFiles={setFiles}
                        />
                        {/* <Button
                            isBordered
                            className="mb--20"
                            type="white"
                            size="small"
                            icon="save"
                            label={tr('Save as template message')}
                        /> */}
                        {messageContainJobCompanyVars && (
                            <>
                                <h4>{tr('Alternative message')}</h4>
                                <p className="normal">
                                    {tr(
                                        'The alternative message allows you to send a coherent message if one of the variables - "Company Name", "Job Title", "Icebreaker", "Industry" or "Location" - are not filled in on the user\'s profile.'
                                    )}
                                </p>
                                {utils.variableButtons(
                                    contentAlternativeRef,
                                    false,
                                    campaign[`action_message_${mess}_alternate`],
                                    (current) => {
                                        const newCamp = { ...campaign };
                                        newCamp[`action_message_${mess}_alternate`] = current;
                                        setCampaign(newCamp);
                                    }
                                )}
                                <Input
                                    disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                                    className="message"
                                    type="textarea"
                                    value={campaign[`action_message_${mess}_alternate`]}
                                    maxlength="10000"
                                    ref={contentAlternativeRef}
                                    onChange={(e) => {
                                        setMessageAlternate(e.target.value);
                                    }}
                                />
                                <Attachments
                                    inputRef={contentAlternativeRef}
                                    message={campaign[`action_message_${mess}_content`]}
                                    setMessage={setMessageAlternate}
                                    files={
                                        campaign[`action_message_${mess}_attachment_alternate`]
                                            ? JSON.parse(campaign[`action_message_${mess}_attachment_alternate`])
                                            : []
                                    }
                                    setFiles={setFilesAlternate}
                                />
                            </>
                        )}
                    </div>
                    <div className="autom-line bulleted mt--40">
                        <span className="bullet" />
                        <div>{tr('When message is sent, move member to column')}</div>
                        <Input
                            disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                            value={campaign[`action_message_${mess}_step_sent_name`]}
                            onChange={(e) => {
                                const newCamp = { ...campaign };
                                newCamp[`action_message_${mess}_step_sent_name`] = e.target.value;
                                setCampaign(newCamp);
                            }}
                        />
                    </div>
                </div>
                <div className={`autom ${campaign[`action_message_${mess}`] && 'activated'}`}>
                    <div className="autom-line stepped">
                        <div className="autom-step step-green">
                            {campaign[`action_message_${mess}`] ? stepIndex + 1 : ''}
                        </div>
                        <span className="bullet bullet-green" />
                        <div className="autom-desc">{tr('If the message is answered, move member to column')}</div>
                    </div>
                    <div className="autom-line mb--40">
                        <div>{tr('From')}</div>
                        <Input disabled value={campaign[`action_message_${mess}_step_sent_name`]} />
                        <div>{tr('To')}</div>
                        <Input
                            disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                            value={campaign.action_answered_step_name}
                            onChange={(e) => {
                                const newCamp = { ...campaign };
                                newCamp.action_answered_step_name = e.target.value;
                                setCampaign(newCamp);
                            }}
                        />
                    </div>
                    <div className="autom-line bulleted">
                        <span className="bullet bullet-green" />
                        <div>{tr('And add labels to member (optional)')}</div>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            isDisabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selected) => {
                                const newCamp = { ...campaign };
                                newCamp[`action_message_${mess}_labels`] = selected.map((item) => item.value);
                                setCampaign(newCamp);
                            }}
                            components={{ Option: LabelOption }}
                            options={labels?.map((label) => ({
                                value: label.id,
                                name: label.name,
                                color: label.color,
                                label: label.name,
                            }))}
                            defaultValue={campaign?.[`action_message_${mess}_labels`].map((current) => {
                                const label = labels.find((item) => item.id === current);
                                return label
                                    ? {
                                          value: label.id,
                                          name: label.name,
                                          color: label.color,
                                          label: label.name,
                                      }
                                    : null;
                            })}
                            styles={{
                                multiValue: (styles, { data }) => ({
                                    ...styles,
                                    backgroundColor: Colors[data.color].light,
                                }),
                            }}
                        />
                        <Labels isClosable context="btn-addlabel" disableSelect />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CampaignMessage;
