/* global chrome */
import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import moment from 'moment';
import useAlertActions from './alert.actions';
import useLeadsActions from './leads.actions';
import useBoardActions from './board.actions';
import useUserActions from './user.actions';
import useCampaignActions from './campaign.actions';
import {
    lnUserState,
    messageState,
    fileState,
    attachmentsState,
    lnUserConversationsState,
    lnUserConversationState,
    messagesState,
    lnUserUpdateConversationLoadingState,
    lnUserUpdateConnectionLoadingState,
    lnUserFollowLoadingState,
    lnUserSendingMessageState,
    syncLnUserState,
} from '../_states/lnuser.states';
import { gifsState, uploadingFileState } from '../_states/template.states';
import {
    bulkSendProgressState,
    selectedUsersState,
    bulkArchiveProgressState,
    bulkDisconnectProgressState,
    bulkUnreadProgressState,
    bulkInviteProgressState,
    bulkSyncProgressState,
    filterConnectionsState,
    bulkAcceptInviteProgressState,
    boardState,
    connectionsState,
} from '../_states/lnusers.states';

import { tr } from '../common/locale';
import useError from '../_helpers/errorsWrapper.helpers';
import { notifsState, extensionState, initialConvSyncState, firstSyncState, userState } from '../_states/user.states';
import {
    scrapSearchState,
    searchesState,
    eventUrlState,
    scrapeEventStatusState,
    scrapLikesState,
    scrapCommentsState,
    postUrlState,
    scrapePostStatusState,
} from '../_states/leads.states';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import Button from '../components/Button';
import Icon from '../components/Icon';
import messageUtils from '../common/messageUtils';
import utils from '../common/utils';

let alertUpdateExtShown = false;
let dataSyncStarted = false;
let profilesPollingInterval;
let notifsPollingInterval;
let scrapPollingInterval;
let campaignPollingInterval;
let scrapTimeout;

export default function useLinkedInActions() {
    const { showError } = useError();
    const alertActions = useAlertActions();
    const leadsActions = useLeadsActions();
    const boardActions = useBoardActions();
    const userActions = useUserActions();
    const campaignActions = useCampaignActions();
    const setSelectedUsers = useSetRecoilState(selectedUsersState);
    const setConnections = useSetRecoilState(connectionsState);
    const setBoard = useSetRecoilState(boardState);
    const setBulkSendProgress = useSetRecoilState(bulkSendProgressState);
    const setBulkSyncProgress = useSetRecoilState(bulkSyncProgressState);
    const setBulkAcceptInviteProgress = useSetRecoilState(bulkAcceptInviteProgressState);
    const setBulkInviteProgress = useSetRecoilState(bulkInviteProgressState);
    const setBulkArchiveProgress = useSetRecoilState(bulkArchiveProgressState);
    const setBulkDisconnectProgress = useSetRecoilState(bulkDisconnectProgressState);
    const setGifs = useSetRecoilState(gifsState);
    const setUploadingFile = useSetRecoilState(uploadingFileState);
    const setBulkUnreadProgress = useSetRecoilState(bulkUnreadProgressState);
    const setSyncLnUser = useSetRecoilState(syncLnUserState);
    const setFirstSync = useSetRecoilState(firstSyncState);
    const setLnUser = useSetRecoilState(lnUserState);
    const setScrapSearch = useSetRecoilState(scrapSearchState);
    const setSearches = useSetRecoilState(searchesState);
    const setMessage = useSetRecoilState(messageState);
    const setScrapeEventStatus = useSetRecoilState(scrapeEventStatusState);
    const setScrapePostStatus = useSetRecoilState(scrapePostStatusState);
    const setFile = useSetRecoilState(fileState);
    const setAttachments = useSetRecoilState(attachmentsState);
    const setLnUserConversations = useSetRecoilState(lnUserConversationsState);
    const setLnUserFollowLoading = useSetRecoilState(lnUserFollowLoadingState);
    const setLnUserSendingMessage = useSetRecoilState(lnUserSendingMessageState);
    const setNotifsState = useSetRecoilState(notifsState);
    const setInitialConvSync = useSetRecoilState(initialConvSyncState);
    const setLnUserUpdateConversationLoading = useSetRecoilState(lnUserUpdateConversationLoadingState);
    const setLnUserUpdateConnectionLoading = useSetRecoilState(lnUserUpdateConnectionLoadingState);
    const setExtensionState = useSetRecoilState(extensionState);
    const setMessages = useSetRecoilState(messagesState);
    const setLnUserConversation = useSetRecoilState(lnUserConversationState);
    const setUser = useSetRecoilState(userState);
    const setEventUrl = useSetRecoilState(eventUrlState);
    const setPostUrl = useSetRecoilState(postUrlState);
    const fetchWrapper = useFetchWrapper();

    function updateInBoard(lnUser) {
        const board = getRecoil(boardState);
        if (board) {
            const newBoard = JSON.parse(JSON.stringify(board));
            newBoard.lanes?.forEach((lane) => {
                const index = lane.cards?.findIndex((item) => item.id === lnUser.id);
                if (index !== -1) {
                    // eslint-disable-next-line no-param-reassign
                    lane.cards[index] = boardActions.lnUserToCard(lnUser);
                    setBoard(newBoard);
                }
            });
        }
    }

    function updateInConnections(lnuser) {
        const connex = getRecoil(connectionsState);
        if (connex) {
            const newConnections = { count: connex.count, items: [...connex.items] };

            const index = newConnections.items.findIndex((item) => item.id === lnuser.id);
            if (index !== -1 && !utils.deepEqual(newConnections.items[index], lnuser)) {
                newConnections.items[index] = lnuser;
                setConnections(newConnections);
            }
        }
    }

    function updateInConversations(conv) {
        const convs = getRecoil(lnUserConversationsState);
        if (convs) {
            const newConvs = [...convs];

            const index = newConvs.findIndex((item) => item.id === conv.id);
            if (index !== -1) {
                newConvs[index] = conv;
                setLnUserConversations(newConvs);
            }
        }
    }

    function updateInSearches(search) {
        const searches = getRecoil(searchesState);
        if (searches) {
            const newSearches = { count: searches.count, items: [...searches.items] };
            const index = newSearches.items.findIndex((item) => item.id === search.id);
            if (index !== -1) {
                newSearches.items[index] = search;
                setSearches(newSearches);
            }
        }
    }
    function refreshMe() {
        return fetchWrapper.get('users/me').then((data) => {
            setUser(data);
        });
    }

    function getNotifs() {
        fetchWrapper
            .get('linkedin/notifs')
            .then((data) => {
                setNotifsState(data);
            })
            .catch(() => null);
    }

    function scrap() {
        const current = getRecoil(scrapSearchState);
        const user = getRecoil(userState);
        console.log('scraping', current);
        if (current) {
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    scrap: current,
                    accessToken: fetchWrapper.getAuth().access,
                    hasSalesNav: user?.has_sales_nav,
                },
                (response) => {
                    refreshMe();
                    console.log('scrap response', response);
                    if (response?.id) {
                        updateInSearches(response);
                    }
                    if (response?.is_processing) {
                        console.log('scraping');
                        setScrapSearch(response);

                        scrapTimeout = setTimeout(() => {
                            console.log('scraping relaunch');
                            scrap();
                        }, utils.getRandomInt(60000, 120000));
                    } else {
                        if (scrapTimeout) {
                            clearTimeout(scrapTimeout);
                            scrapTimeout = null;
                        }
                        setScrapSearch(null);
                    }
                }
            );
        }
    }

    function startScrap() {
        fetchWrapper
            .get('searches/processing')
            .then((response) => {
                if (response && response.length > 0) {
                    const current = getRecoil(scrapSearchState);
                    if (response.length === 0) {
                        setScrapSearch(null);
                    } else if (response[0].id !== current?.id) {
                        setScrapSearch(response[0]);
                        if (!scrapTimeout) {
                            scrap();
                        }
                    }
                }
            })
            .catch(() => null);
    }

    function startCampaign() {
        const user = getRecoil(userState);
        if (!utils.automationsHasAccess(user)) {
            return;
        }
        campaignActions.refreshStatsTasks();
        fetchWrapper
            .get('users/me/tasks')
            .then((response) => {
                if (response && response.length > 0) {
                    chrome.runtime.sendMessage(`${process.env.REACT_APP_CHROME_EXTENSION_ID}`, {
                        campaign: response,
                        accessToken: fetchWrapper.getAuth().access,
                    });
                }
            })
            .catch(() => null);
    }

    function scrapePost(name) {
        setScrapePostStatus(null);
        const postUrl = getRecoil(postUrlState);
        if (postUrl.indexOf('https://www.linkedin.com/posts/') !== 0) {
            setScrapePostStatus('wrongurl');
            return;
        }

        let postId = postUrl.replace('https://www.linkedin.com/posts/', '');
        console.log('scrape', postId);
        if (postId && postId.indexOf('/') !== -1) {
            postId = postId.slice(0, postId.indexOf('/'));
        }
        if (postId && postId.indexOf('?') !== -1) {
            postId = postId.slice(0, postId.indexOf('?'));
        }
        console.log('scrape', postId);
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                scrapPost: postId,
                scrapComments: getRecoil(scrapCommentsState),
                scrapLikes: getRecoil(scrapLikesState),
                accessToken: fetchWrapper.getAuth().access,
                name,
            },
            (response) => {
                if (response.error) {
                    setScrapePostStatus('sraperror');
                } else {
                    setPostUrl(null);
                    leadsActions.list(true);
                    startScrap();
                }
            }
        );
    }

    function scrapeEvent(name) {
        setScrapeEventStatus(null);
        const eventUrl = getRecoil(eventUrlState);
        if (eventUrl.indexOf('https://www.linkedin.com/events/') !== 0) {
            setScrapeEventStatus('wrongurl');
            return;
        }

        let eventId = eventUrl.replace('https://www.linkedin.com/events/', '');
        if (eventId) {
            if (eventId.indexOf('/') !== -1) {
                eventId = eventId.slice(0, eventId.indexOf('/'));
            }
            if (eventId && eventId.length > 19) {
                eventId = eventId.slice(eventId.length - 19);
            }
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    importSearch: `https://www.linkedin.com/search/results/people/?eventAttending=%5B%${eventId}%22%5D&origin=EVENT_PAGE_CANNED_SEARCH&sid=OdE`,
                    importSearchApi: `/voyager/api/graphql?includeWebMetadata=true&variables=(start:0,origin:EVENT_PAGE_CANNED_SEARCH,query:(flagshipSearchIntent:SEARCH_SRP,queryParameters:List((key:eventAttending,value:List(${eventId})),(key:resultType,value:List(PEOPLE))),includeFiltersInResponse:false))&queryId=voyagerSearchDashClusters.b449eb65ca39a0481b5551601610a806`,
                    max: 1000,
                    name,
                },
                (response) => {
                    if (response.error) {
                        setScrapeEventStatus('sraperror');
                    } else {
                        setEventUrl(null);
                        leadsActions.list(true);
                        startScrap();
                    }
                }
            );
        }
    }

    function initialSync() {
        const user = getRecoil(userState);
        if (user.plan.product === 'SCRAPE') {
            return;
        }
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                initialSync: true,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                if (response) {
                    setExtensionState('ok');
                }
            }
        );
    }
    function checkExtensionVersion() {
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                getExtensionVersion: true,
            },
            (data) => {
                console.log(parseFloat(data.version, 10), parseFloat(process.env.REACT_APP_EXTENSION_VERSION, 10));
                if (
                    !alertUpdateExtShown &&
                    data?.version &&
                    parseFloat(data.version, 10) < parseFloat(process.env.REACT_APP_EXTENSION_VERSION, 10)
                ) {
                    alertUpdateExtShown = true;
                    alertActions.info(
                        <span className="new-version">
                            <span className="label">
                                <Icon fill fillColor="#ffc200" icon="star-filled" />
                                <span>{tr('New Kanbox version!')}</span>
                            </span>
                            <strong>{tr('Please update your Chrome extension:')}</strong>
                            <Button
                                label={tr('Update')}
                                onClick={() => {
                                    chrome.runtime.sendMessage(`${process.env.REACT_APP_CHROME_EXTENSION_ID}`, {
                                        openExtensionUpdatePage: true,
                                    });
                                }}
                            />
                        </span>
                    );
                }
            }
        );
    }
    function updateLnUser(paramLnUser, forceLinkedinRefresh) {
        const lnUserToConsider = paramLnUser || getRecoil(lnUserState);
        const user = getRecoil(userState);
        const isScrapedFromCsv =
            lnUserToConsider && lnUserToConsider.linkedin_id === lnUserToConsider.linkedin_public_id;
        if (
            !forceLinkedinRefresh &&
            !isScrapedFromCsv &&
            lnUserToConsider &&
            moment().diff(lnUserToConsider.updated_at, 'day') < 7
        ) {
            // Only refresh if if was not refreshed 24h ago
            return;
        }
        if (user?.quotas?.daily_import_standard <= 0) {
            return;
        }
        if (getRecoil(lnUserState) && !paramLnUser) {
            setSyncLnUser(true);
        }
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                profile: lnUserToConsider.linkedin_id,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                console.log('setSyncLnUser', getRecoil(lnUserState) && !paramLnUser);
                const currentLnUser = getRecoil(lnUserState);
                if (response && currentLnUser && currentLnUser.linkedin_id === response.linkedin_id) {
                    setLnUser(response);
                }
                updateInBoard(response);
                const connections = getRecoil(connectionsState);
                if (connections && response?.id) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === response.id);
                    if (index !== -1 && !utils.deepEqual(newConnections.items[index], response)) {
                        newConnections.items[index] = response;
                        setConnections(newConnections);
                    }
                }
                if (getRecoil(lnUserState) && !paramLnUser) {
                    setSyncLnUser(false);
                }
                refreshMe();
            }
        );
    }

    function getLnUserConversations(currentUser) {
        const userToConsider = currentUser || getRecoil(lnUserState);
        const lnUserConversation = getRecoil(lnUserConversationState);
        console.log('getLnUserConversations', userToConsider, currentUser);
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                conversations: true,
                id: userToConsider?.lead?.linkedin_id,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                if (response && response.items?.length > 0) {
                    const currentLnUser = getRecoil(lnUserState);
                    const updatedLnUser = response?.items[0];
                    updateInConnections(updatedLnUser);
                    const board = getRecoil(boardState);
                    if (board) {
                        const newBoard = JSON.parse(JSON.stringify(board));
                        newBoard.lanes?.forEach((lane) => {
                            const index = lane.cards?.findIndex((item) => item.id === updatedLnUser.id);
                            if (index !== -1) {
                                // eslint-disable-next-line no-param-reassign
                                lane.cards[index] = boardActions.lnUserToCard(updatedLnUser);
                                setBoard(newBoard);
                            }
                        });
                    }
                    console.log(
                        'getLnUserConversations',
                        updatedLnUser,
                        currentLnUser,
                        updatedLnUser.id === currentLnUser?.id
                    );
                    if (updatedLnUser.id === currentLnUser?.id) {
                        const lastConv =
                            updatedLnUser.conversations_ids?.length > 0 ? updatedLnUser.conversations_ids[0] : null;
                        console.log('lastConv', lastConv);
                        setLnUserConversations(updatedLnUser.conversations_ids);
                        if (lastConv) {
                            setLnUserUpdateConversationLoading(false);
                            if (!lnUserConversation) {
                                const unreadConv = updatedLnUser.conversations_ids.find((item) => !item.is_read);
                                setLnUserConversation(
                                    unreadConv?.linkedin_id || updatedLnUser.conversations_ids[0].linkedin_id
                                );
                            }
                        }
                    }
                } else {
                    setLnUserConversations(response?.items);
                    setLnUserUpdateConversationLoading(false);
                }
            }
        );
    }

    function deleteConversation(conversation) {
        setLnUserUpdateConversationLoading('delete');
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                deleteConversation: conversation.id,
                linkedInId: conversation.linkedin_id,
                accessToken: fetchWrapper.getAuth().access,
            },
            () => {
                setMessages(null);
                setLnUserConversation(null);
                getLnUserConversations();
            }
        );
    }

    function removeConnection() {
        setLnUserUpdateConnectionLoading('remove');
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                removeConnection: getRecoil(lnUserState).id,
                linkedInPublicId: getRecoil(lnUserState).lead.linkedin_public_id,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                setLnUserUpdateConnectionLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (response && currentLnUser && currentLnUser.lead?.linkedin_id === response.lead?.linkedin_id) {
                    setLnUser(response);
                } else {
                    showError(tr('Could not remove connection'));
                }
            }
        );
    }

    function acceptConnection(accept) {
        setLnUserUpdateConnectionLoading(accept ? 'accept' : 'ignore');
        const lnUser = getRecoil(lnUserState);
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                acceptConnection: lnUser.invitation_id.replace('fs_relInvitation', 'fsd_invitation'),
                lnUserId: lnUser.id,
                secret: lnUser.invitation_secret,
                accept,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                setLnUserUpdateConnectionLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (response && currentLnUser && currentLnUser.lead?.linkedin_id === response.lead?.linkedin_id) {
                    setLnUser(response);
                } else {
                    showError(tr('Could not accept connection'));
                }
            }
        );
    }

    function withdrawInvitation() {
        setLnUserUpdateConnectionLoading('ignore');
        const lnUser = getRecoil(lnUserState);
        if (lnUser.invitation_id) {
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    withdrawInvitation: lnUser.invitation_id.replace('fs_relInvitation', 'fsd_invitation'),
                    lnUserId: lnUser.id,
                    accessToken: fetchWrapper.getAuth().access,
                },
                (response) => {
                    setLnUserUpdateConnectionLoading(false);
                    const currentLnUser = getRecoil(lnUserState);
                    if (response && currentLnUser && currentLnUser.lead?.linkedin_id === response.lead?.linkedin_id) {
                        setLnUser(response);
                    } else {
                        showError(tr('Could not withdraw connection request'));
                    }
                }
            );
        } else {
            setLnUserUpdateConnectionLoading(false);
        }
    }

    function follow(following) {
        setLnUserFollowLoading(true);
        const lnUser = getRecoil(lnUserState);
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                linkedInId: lnUser.lead?.linkedin_id,
                followUser: lnUser.id,
                following,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                setLnUserFollowLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (response && currentLnUser && currentLnUser.lead?.linkedin_id === response.lead?.linkedin_id) {
                    userActions.decreaseQuotas('daily_quota_follow_used');
                    setLnUser(response);
                } else {
                    showError(tr('Could not follow user'));
                }
            }
        );
    }

    function invite(invitationText) {
        setLnUserUpdateConnectionLoading('invitation');
        const lnUser = getRecoil(lnUserState);
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                invite: lnUser.id,
                lnUserLinkedInId: lnUser.lead?.linkedin_id,
                text: invitationText,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                if (response === 'Too many invitations') {
                    showError(tr('Too many invitations sent'));
                    return;
                }
                setLnUserUpdateConnectionLoading(false);
                refreshMe();
                const currentLnUser = getRecoil(lnUserState);
                if (response && currentLnUser && currentLnUser.lead?.linkedin_id === response.lead?.linkedin_id) {
                    userActions.decreaseQuotas('daily_quota_invitations_used');
                    setLnUser(response);
                } else {
                    showError(tr('Could not invite user'));
                }
            }
        );
    }

    function reactOnMessage(messageUrn, emoji, react) {
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                reactOnMessage: messageUrn,
                emoji,
                react,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                if (response >= 400) {
                    showError(tr('Could not react to this message'));
                }
            }
        );
    }

    function getMessages(before, more, currentConv) {
        const lnUser = getRecoil(lnUserState);
        const lnUserConversation = getRecoil(lnUserConversationState);
        const lnUserConversations = getRecoil(lnUserConversationsState);
        const currentConversation =
            currentConv ||
            lnUserConversations?.find((element) => element.linkedin_id.indexOf(lnUserConversation) !== -1);
        const messages = getRecoil(messagesState);
        console.log('getMessages', currentConversation);

        const lnUserId = lnUser?.id;
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                conversationId: currentConversation.id,
                messages: currentConversation.linkedin_id,
                before,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                const currentLnUser = getRecoil(lnUserState);
                if (response && currentLnUser?.id === lnUserId) {
                    response.elements.reverse();
                    if (more) {
                        const newMessagesElements = [...messages.elements, ...response.elements];
                        setMessages({ elements: newMessagesElements });
                    } else {
                        setMessages(response);
                    }
                }
            }
        );
    }

    function searchGifs(text) {
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                gifs: text,
            },
            (response) => {
                setGifs(response);
            }
        );
    }

    function uploadTemplateFile(file, files, setFiles) {
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                accessToken: fetchWrapper.getAuth().access,
                templateFile: file,
            },
            (response) => {
                setUploadingFile(false);
                if (response) {
                    const templateFiles = files || [];
                    const newTemplateFiles = [...templateFiles];
                    newTemplateFiles.push(response);

                    setFiles(newTemplateFiles);
                } else {
                    showError(tr('Could not upload this kind of file'));
                }
            }
        );
    }

    function sendMessage(template, gif) {
        console.log('attachments', getRecoil(attachmentsState));
        setLnUserSendingMessage(true);
        const lnUser = getRecoil(lnUserState);
        const message = getRecoil(messageState);
        const file = getRecoil(fileState);
        const attachments = getRecoil(attachmentsState);
        const lnUserConversation = getRecoil(lnUserConversationState);
        const user = getRecoil(userState);
        const processedMessage = utils.replaceVariables(message, lnUser);
        console.log('sendMessage', {
            sendmessage: true,
            template,
            message: processedMessage,
            conversation: lnUserConversation,
            recipient: lnUser.linkedin_id,
            accessToken: fetchWrapper.getAuth().access,
            senderLinkedinId: user.linkedin_id,
            inMailPremium: lnUser.lead.is_open_profile && !lnUser.is_connection,
            file,
            senderId: lnUser.id,
            gif,
            attachments,
        });
        const isInMailPremium = lnUser.lead.is_open_profile && !lnUser.is_connection;
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                sendmessage: true,
                template,
                message: processedMessage,
                conversation: lnUserConversation,
                recipient: lnUser.linkedin_id,
                accessToken: fetchWrapper.getAuth().access,
                senderLinkedinId: user.linkedin_id,
                inMailPremium: isInMailPremium,
                file,
                senderId: lnUser.id,
                gif: gif ? JSON.parse(JSON.stringify(gif)) : gif,
                attachments,
            },
            (response) => {
                if (response === 201 || response === 200) {
                    userActions.decreaseQuotas('daily_quota_messages_used');
                    if (isInMailPremium) {
                        userActions.decreaseQuotas('daily_quota_openprofile_used');
                    }
                    console.log('response lnUserConversation', lnUserConversation);
                    if (!lnUserConversation) {
                        getLnUserConversations();
                    } else {
                        getLnUserConversations();
                        getMessages();
                    }
                    setMessage('');
                    setFile(null);
                    setAttachments(null);
                } else {
                    showError(tr('Message cannot not be sent to this user.'));
                }
                setLnUserSendingMessage(false);
            }
        );
    }

    function bulkSendMessage(
        messageToSend,
        alternative,
        templateFiles,
        templateFilesAlternate,
        template,
        currentSeletedUsers
    ) {
        const selectedUsers = getRecoil(selectedUsersState);
        const usersToConsider = currentSeletedUsers || selectedUsers;
        const user = getRecoil(userState);
        if (!messageToSend) {
            return;
        }
        const bulkSendProgress = getRecoil(bulkSendProgressState);
        let index = 0;
        if (bulkSendProgress === undefined) {
            setBulkSendProgress(0);
        } else if (bulkSendProgress === 'done') {
            return;
        } else if (bulkSendProgress === usersToConsider?.length - 1) {
            setBulkSendProgress('done');
            setTimeout(
                () => {
                    setSelectedUsers([]);
                    setBulkSendProgress(undefined);
                },
                bulkSendProgress === undefined ? 0 : utils.getRandomInt(40000, 10000)
            );
            return;
        } else {
            index = bulkSendProgress + 1;
            setBulkSendProgress(bulkSendProgress + 1);
        }
        const userToSend = usersToConsider[index];
        let processedMessage;
        let attach;
        if (!utils.canReplaceVariables(messageToSend, userToSend) && alternative) {
            processedMessage = utils.replaceVariables(alternative, userToSend);
            attach = templateFilesAlternate;
        } else {
            processedMessage = utils.replaceVariables(messageToSend, userToSend);
            attach = templateFiles;
        }
        if (!messageUtils.canSendMessage(user, userToSend)) {
            bulkSendMessage(messageToSend, alternative, template, currentSeletedUsers);
        } else {
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    sendmessage: true,
                    message: processedMessage,
                    conversation: null,
                    recipient: userToSend.linkedin_id,
                    senderLinkedinId: user.linkedin_id,
                    inMailPremium: userToSend?.lead?.is_open_profile && !userToSend.is_connection,
                    accessToken: fetchWrapper.getAuth().access,
                    senderId: userToSend.id,
                    template,
                    attachments: attach,
                },
                () => {
                    setTimeout(
                        () => {
                            userActions.decreaseQuotas('daily_quota_messages_used');
                            bulkSendMessage(
                                messageToSend,
                                alternative,
                                templateFiles,
                                templateFilesAlternate,
                                template,
                                currentSeletedUsers
                            );
                            getLnUserConversations(userToSend);
                            updateLnUser(userToSend);
                        },
                        bulkSendProgress === undefined ? 0 : utils.getRandomInt(50000, 15000)
                    );
                }
            );
        }
    }

    function bulkInvite(messageToSend, alternative, currentSeletecdUsers) {
        const selectedUsers = getRecoil(selectedUsersState);
        const usersToConsider = currentSeletecdUsers || selectedUsers;
        const bulkInviteProgress = getRecoil(bulkInviteProgressState);
        let index = 0;
        if (bulkInviteProgress === undefined) {
            setBulkInviteProgress(0);
        } else if (bulkInviteProgress === 'done') {
            return;
        } else if (bulkInviteProgress === usersToConsider?.length - 1) {
            refreshMe();
            setBulkInviteProgress('done');
            setTimeout(
                () => {
                    setSelectedUsers([]);
                    setBulkInviteProgress(undefined);
                },
                bulkInviteProgress === undefined ? 0 : utils.getRandomInt(20000, 5000)
            );
            return;
        } else {
            index = bulkInviteProgress + 1;
            setBulkInviteProgress(bulkInviteProgress + 1);
        }
        const userToSend = usersToConsider[index];
        let processedMessage;
        if (alternative && !utils.canReplaceVariables(messageToSend, userToSend)) {
            processedMessage = utils.replaceVariables(alternative, userToSend);
        } else {
            processedMessage = utils.replaceVariables(messageToSend, userToSend);
        }
        if (userToSend.is_connection || userToSend.invitation_id) {
            bulkInvite(messageToSend, alternative, currentSeletecdUsers);
        } else {
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    invite: userToSend.id,
                    lnUserLinkedInId: userToSend.linkedin_id,
                    text: processedMessage,
                    accessToken: fetchWrapper.getAuth().access,
                },
                (data) => {
                    if (data) {
                        userActions.decreaseQuotas('daily_quota_invitations_used');
                        updateInConnections(data);
                    }
                    setTimeout(
                        () => {
                            bulkInvite(messageToSend, alternative, currentSeletecdUsers);
                        },
                        bulkInviteProgress === undefined ? 0 : utils.getRandomInt(50000, 15000)
                    );
                }
            );
        }
    }

    function bulkArchive(archive) {
        const selectedUsers = getRecoil(selectedUsersState);
        const bulkArchiveProgress = getRecoil(bulkArchiveProgressState);
        let index = 0;
        if (bulkArchiveProgress === undefined) {
            setBulkArchiveProgress(0);
        } else if (bulkArchiveProgress === 'done') {
            return;
        } else if (bulkArchiveProgress === selectedUsers?.length - 1) {
            setBulkArchiveProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkArchiveProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkArchiveProgress + 1;
            setBulkArchiveProgress(bulkArchiveProgress + 1);
        }
        const userToArchive = selectedUsers[index];
        if (userToArchive.conversations_ids) {
            userToArchive.conversations_ids.forEach((conv) => {
                let cb = () => null;
                if (userToArchive.conversations_ids.indexOf(conv) === userToArchive.conversations_ids.length - 1) {
                    cb = () => {
                        const connections = getRecoil(connectionsState);
                        const filterConnections = getRecoil(filterConnectionsState);
                        // remove user from inbox
                        if (filterConnections === 'conversations' || filterConnections === 'archivedconv') {
                            const newConnections = { count: connections.count, items: [...connections.items] };
                            const indexConnect = newConnections.items.findIndex((item) => item.id === userToArchive.id);
                            if (indexConnect !== -1) {
                                newConnections.items.splice(indexConnect, 1);
                                setConnections(newConnections);
                            }
                        }
                        bulkArchive(archive);
                    };
                }
                const user = getRecoil(userState);
                chrome.runtime.sendMessage(
                    `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                    {
                        updateConversation: conv.id,
                        userLinkedInId: user.linkedin_id,
                        linkedInId: conv.linkedin_id,
                        accessToken: fetchWrapper.getAuth().access,
                        action: archive ? 'archive' : 'unarchive',
                    },
                    cb
                );
            });
        }
    }

    function bulkDisconnect() {
        const selectedUsers = getRecoil(selectedUsersState);
        const bulkDisconnectProgress = getRecoil(bulkDisconnectProgressState);
        let index = 0;
        if (bulkDisconnectProgress === undefined) {
            setBulkDisconnectProgress(0);
        } else if (bulkDisconnectProgress === 'done') {
            return;
        } else if (bulkDisconnectProgress === selectedUsers?.length - 1) {
            setBulkDisconnectProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkDisconnectProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkDisconnectProgress + 1;
            setBulkDisconnectProgress(bulkDisconnectProgress + 1);
        }
        const userToDisconnect = selectedUsers[index];
        if (userToDisconnect.conversations_ids) {
            const cb = () => {
                const connections = getRecoil(connectionsState);
                const filterConnections = getRecoil(filterConnectionsState);
                // remove user from inbox
                if (filterConnections === 'connections') {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const indexConnect = newConnections.items.findIndex((item) => item.id === userToDisconnect.id);
                    if (indexConnect !== -1) {
                        newConnections.items.splice(indexConnect, 1);
                        setConnections(newConnections);
                    }
                }
                bulkDisconnect();
            };
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    removeConnection: userToDisconnect.id,
                    linkedInPublicId: userToDisconnect.lead.linkedin_public_id,
                    accessToken: fetchWrapper.getAuth().access,
                },
                cb
            );
        }
    }

    function bulkUnread(unread) {
        const selectedUsers = getRecoil(selectedUsersState);
        const bulkUnreadProgress = getRecoil(bulkUnreadProgressState);
        let index = 0;
        if (bulkUnreadProgress === undefined) {
            setBulkUnreadProgress(0);
        } else if (bulkUnreadProgress === 'done') {
            return;
        } else if (bulkUnreadProgress === selectedUsers?.length - 1) {
            setBulkUnreadProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkUnreadProgress(undefined);
                getNotifs();
            }, 1000);
            return;
        } else {
            index = bulkUnreadProgress + 1;
            setBulkUnreadProgress(bulkUnreadProgress + 1);
        }
        const userToUnread = selectedUsers[index];
        if (userToUnread.conversations_ids?.length > 0) {
            const [conv] = userToUnread.conversations_ids;
            const cb = () => {
                const connections = getRecoil(connectionsState);
                const newConnections = { count: connections.count, items: [...connections.items] };
                const indexConnect = newConnections.items.findIndex((item) => item.id === userToUnread.id);
                if (indexConnect !== -1) {
                    const newUser = { ...newConnections.items[indexConnect] };
                    newUser.is_read = !unread;
                    newConnections.items[indexConnect] = newUser;
                    setConnections(newConnections);
                }
                bulkUnread(unread);
            };

            const user = getRecoil(userState);
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    updateConversation: conv.id,
                    userLinkedInId: user.linkedin_id,
                    linkedInId: conv.linkedin_id,
                    accessToken: fetchWrapper.getAuth().access,
                    action: unread ? 'unread' : 'read',
                },
                cb
            );
        }
    }

    function bulkSync(currentSeletecdUsers) {
        const selectedUsers = getRecoil(selectedUsersState);
        const usersToConsider = currentSeletecdUsers || selectedUsers;
        const bulkSyncProgress = getRecoil(bulkSyncProgressState);
        let index = 0;
        if (bulkSyncProgress === undefined) {
            setBulkSyncProgress(0);
        } else if (bulkSyncProgress === 'done') {
            return;
        } else if (bulkSyncProgress === usersToConsider?.length - 1) {
            setBulkSyncProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkSyncProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkSyncProgress + 1;
            setBulkSyncProgress(bulkSyncProgress + 1);
        }
        const userToSend = usersToConsider[index];

        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                profile: userToSend.linkedin_id,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                const connections = getRecoil(connectionsState);
                if (connections) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const indexConnect = newConnections.items.findIndex((item) => item.id === response.id);
                    if (indexConnect !== -1 && !utils.deepEqual(newConnections.items[index], response)) {
                        newConnections.items[indexConnect] = response;
                        setConnections(newConnections);
                    }
                }
                updateInBoard(response);
                bulkSync(currentSeletecdUsers);
            }
        );
    }

    function bulkAcceptInvite(currentSeletecdUsers, accept) {
        const selectedUsers = getRecoil(selectedUsersState);
        const usersToConsider = currentSeletecdUsers || selectedUsers;
        const bulkAcceptInviteProgress = getRecoil(bulkAcceptInviteProgressState);
        let index = 0;
        if (bulkAcceptInviteProgress === undefined) {
            setBulkAcceptInviteProgress(0);
        } else if (bulkAcceptInviteProgress === 'done') {
            return;
        } else if (bulkAcceptInviteProgress === usersToConsider?.length - 1) {
            setBulkAcceptInviteProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkAcceptInviteProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkAcceptInviteProgress + 1;
            setBulkAcceptInviteProgress(bulkAcceptInviteProgress + 1);
        }
        const userToSend = usersToConsider[index];
        const body = {
            lnUserId: userToSend.id,
            accessToken: fetchWrapper.getAuth().access,
        };
        let canDoAction = false;
        if (accept && userToSend.invitation_type === 'PENDING') {
            body.acceptConnection = userToSend.invitation_id;
            body.secret = userToSend.invitation_secret;
            body.accept = true;
            canDoAction = true;
        } else if (userToSend.invitation_type === 'SENT' && userToSend.invitation_id) {
            body.withdrawInvitation = userToSend.invitation_id.replace('fs_relInvitation', 'fsd_invitation');
            canDoAction = true;
        }
        if (!canDoAction) {
            bulkAcceptInvite(currentSeletecdUsers, accept);
        } else {
            chrome.runtime.sendMessage(`${process.env.REACT_APP_CHROME_EXTENSION_ID}`, body, (response) => {
                const connections = getRecoil(connectionsState);
                const newConnections = { count: connections.count, items: [...connections.items] };
                const indexConnect = newConnections.items.findIndex((item) => item.id === response?.id);
                const filterConnections = getRecoil(filterConnectionsState);
                if (indexConnect !== -1) {
                    if ((filterConnections === 'SENT' && !accept) || (filterConnections === 'PENDING' && accept)) {
                        newConnections.items.splice(indexConnect, 1);
                    } else {
                        newConnections.items[indexConnect] = response;
                    }
                    setConnections(newConnections);
                }
                bulkAcceptInvite(currentSeletecdUsers, accept);
            });
        }
    }

    function startConversationsSync(filter, max) {
        const user = getRecoil(userState);
        if (user.plan.product === 'SCRAPE') {
            setInitialConvSync(true);
            return;
        }
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                syncConversations: true,
                filter,
                maxConv: max,
                accessToken: fetchWrapper.getAuth().access,
            },
            (response) => {
                if (response) {
                    if (filter === 'unread') {
                        setInitialConvSync(true);
                        refreshMe();
                        getNotifs();
                    }
                }
            }
        );
    }

    function startDataSync() {
        const user = getRecoil(userState);
        if (user.plan.product === 'SCRAPE' || (user.plan?.product === 'TRIAL' && utils.trialEnd(user) <= 0)) {
            return;
        }
        if (!dataSyncStarted) {
            dataSyncStarted = true;
        }
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                syncData: true,
                linkedinId: user.linkedin_id,
                accessToken: fetchWrapper.getAuth().access,
            },
            () => {
                setFirstSync(true);
                setTimeout(() => startDataSync(), 5 * 60000);
            }
        );
    }

    function updateConversation(conversation, action) {
        const user = getRecoil(userState);
        const currentLnUserInState = getRecoil(lnUserState);
        const isUnread = currentLnUserInState && !currentLnUserInState.is_read;

        if (conversation && user) {
            setLnUserUpdateConversationLoading(action);
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    updateConversation: conversation.id,
                    userLinkedInId: user.linkedin_id,
                    linkedInId: conversation.linkedin_id,
                    accessToken: fetchWrapper.getAuth().access,
                    action,
                },
                (data) => {
                    const currentLnUser = getRecoil(lnUserState);
                    if (currentLnUser?.id === data.participant.id) {
                        updateInConversations(data);
                        setLnUserUpdateConversationLoading(false);
                        setLnUser(data.participant);
                    }
                    const connections = getRecoil(connectionsState);
                    const filterConnections = getRecoil(filterConnectionsState);
                    const newConnections = {
                        count: connections ? connections.count : 0,
                        items: connections ? [...connections.items] : [],
                    };
                    const indexConnect = newConnections.items.findIndex((item) => item.id === data.participant.id);

                    if (indexConnect !== -1) {
                        if (
                            (filterConnections === 'conversations' && action === 'archive') ||
                            (filterConnections === 'archivedconv' && action === 'unarchive')
                        ) {
                            newConnections.items.splice(indexConnect, 1);
                            setConnections(newConnections);
                        } else if (!utils.deepEqual(newConnections.items[indexConnect], data.participant)) {
                            newConnections.items[indexConnect] = data.participant;
                            setConnections(newConnections);
                        }
                    }
                    const notifs = getRecoil(notifsState);
                    if (notifs.inbox > 0 && action === 'read' && isUnread) {
                        getNotifs();
                    }
                }
            );
        }
    }

    function likePost() {
        const user = getRecoil(userState);
        const currentLnUserInState = getRecoil(lnUserState);
        console.log('****like post', currentLnUserInState);

        if (currentLnUserInState) {
            chrome.runtime.sendMessage(
                `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
                {
                    user,
                    likePost: currentLnUserInState.lead,
                    accessToken: fetchWrapper.getAuth().access,
                },
                () => {}
            );
        }
    }

    function updateMessages(currentMessage) {
        const lnUserConversation = getRecoil(lnUserConversationState);
        const messages = getRecoil(messagesState);
        if (messages?.elements && currentMessage && lnUserConversation === currentMessage.conversationUrn) {
            const found = messages?.elements.find((mess) => mess.dashEntityUrn === currentMessage.dashEntityUrn);
            if (!found) {
                const newMessagesElements = [currentMessage, ...messages.elements];
                setMessages({ elements: newMessagesElements });
            }
        }
    }

    function updateReaction(reaction) {
        const messages = getRecoil(messagesState);
        if (reaction && messages?.elements) {
            const newElements = [];
            messages.elements.forEach((mess) => {
                if (mess.entityUrn === reaction.eventUrn) {
                    const newReactions = [];
                    let found = false;
                    mess.reactionSummaries.forEach((reac) => {
                        if (reac.emoji === reaction.reactionSummary.emoji) {
                            found = true;
                            if (reaction.reactionSummary.count > 0) {
                                newReactions.push(reaction.reactionSummary);
                            }
                        } else {
                            newReactions.push(reac);
                        }
                    });
                    if (!found) {
                        newReactions.push(reaction.reactionSummary);
                    }
                    const newMess = { ...mess, ...{ reactionSummaries: newReactions } };
                    newElements.push(newMess);
                } else {
                    newElements.push(mess);
                }
            });
            setMessages({ elements: newElements });
        }
    }

    function polling() {
        console.log('Start polling');
        startDataSync();

        const notifsPolling = () => {
            try {
                startConversationsSync('unread', 50);
            } catch (e) {
                console.log(e);
            }
        };
        const scrapPolling = () => {
            try {
                startScrap();
            } catch (e) {
                console.log(e);
            }
        };
        const campaignPolling = () => {
            try {
                startCampaign();
            } catch (e) {
                console.log(e);
            }
        };
        if (scrapPollingInterval) {
            clearInterval(scrapPollingInterval);
        }
        if (campaignPollingInterval) {
            clearInterval(campaignPollingInterval);
        }
        if (notifsPollingInterval) {
            clearInterval(notifsPollingInterval);
        }
        if (profilesPollingInterval) {
            clearInterval(profilesPollingInterval);
        }
        scrapPollingInterval = setInterval(scrapPolling, 60 * 1000 * 5);
        campaignPollingInterval = setInterval(campaignPolling, 60 * 1000 * 3);
        notifsPollingInterval = setInterval(notifsPolling, 60 * 1000 * 10);
        checkExtensionVersion();
        notifsPolling();
        scrapPolling();
        campaignPolling();
    }

    return {
        uploadTemplateFile,
        searchGifs,
        scrapeEvent,
        scrapePost,
        checkExtensionVersion,
        getNotifs,
        refreshMe,
        polling,
        initialSync,
        updateReaction,
        reactOnMessage,
        bulkArchive,
        bulkAcceptInvite,
        bulkSync,
        bulkInvite,
        bulkSendMessage,
        bulkDisconnect,
        bulkUnread,
        follow,
        withdrawInvitation,
        invite,
        acceptConnection,
        likePost,
        removeConnection,
        deleteConversation,
        updateConversation,
        updateMessages,
        sendMessage,
        startConversationsSync,
        getMessages,
        updateLnUser,
        getLnUserConversations,
    };
}
