import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import moment from 'moment';
import useBoardActions from './board.actions';
import userLinkedInactions from './linkedin.actions';
import { notifsState } from '../_states/user.states';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import {
    syncState,
    connectionsState,
    filterNameState,
    filterCompanyState,
    enrichLoadingState,
    filterJobState,
    filterLocationState,
    filterHeadlineState,
    filterLabelsState,
    filterNoLabelState,
    boardFilterState,
    filterConnectionsState,
    selectedUsersState,
    bulkBoardProgressState,
    bulkRemindProgressState,
    bulkLabelProgressState,
    bulkHideProgressState,
    bulkProcessLeadsProgressState,
    bulkMoveProgressState,
    loadingState,
    boardState,
    moveLaneLoadingState,
    csvExportState,
    filterInboxState,
    initBoardLanesState,
    notesState,
} from '../_states/lnusers.states';
import { lnUserState, lnUserUpdateConnectionLoadingState, loadingRemindState } from '../_states/lnuser.states';
import { selectedLeadState, leadsState } from '../_states/leads.states';
import useError from '../_helpers/errorsWrapper.helpers';
import utils from '../common/utils';

let listController;

export default function useLeadActions() {
    const fetchWrapper = useFetchWrapper();
    const linkedInActions = userLinkedInactions();
    const boardActions = useBoardActions();
    const setSync = useSetRecoilState(syncState);
    const setLnUser = useSetRecoilState(lnUserState);
    const setLnUserUpdateConnectionLoading = useSetRecoilState(lnUserUpdateConnectionLoadingState);
    const setSelectedLead = useSetRecoilState(selectedLeadState);
    const setInitBoardLanes = useSetRecoilState(initBoardLanesState);
    const setSelectedUsers = useSetRecoilState(selectedUsersState);
    const setConnections = useSetRecoilState(connectionsState);
    const setLeads = useSetRecoilState(leadsState);
    const setBoard = useSetRecoilState(boardState);
    const setLoadingRemind = useSetRecoilState(loadingRemindState);
    const setLoading = useSetRecoilState(loadingState);
    const setBulkBoardProgress = useSetRecoilState(bulkBoardProgressState);
    const setEnrichLoading = useSetRecoilState(enrichLoadingState);
    const setMoveLaneLoadingState = useSetRecoilState(moveLaneLoadingState);
    const setCsvExport = useSetRecoilState(csvExportState);
    const setNotes = useSetRecoilState(notesState);
    const setBulkHideProgress = useSetRecoilState(bulkHideProgressState);
    const setBulkRemindProgress = useSetRecoilState(bulkRemindProgressState);
    const setBulkProcessLeadsProgress = useSetRecoilState(bulkProcessLeadsProgressState);
    const setBulkMoveProgress = useSetRecoilState(bulkMoveProgressState);
    const setBulkLabelProgress = useSetRecoilState(bulkLabelProgressState);
    const { fetchNetworkError } = useError();
    function syncConnections() {
        const sync = getRecoil(syncState);
        if (!sync) {
            fetchWrapper.get('linkedin/connections/sync');
            const interval = setInterval(() => {
                fetchWrapper.get('linkedin/connections/sync');
            }, 60 * 1000 * 30);
            setSync(interval);
        }
    }
    function updateInConnections(lnusers) {
        const connections = getRecoil(connectionsState);
        if (connections) {
            const newConnections = { count: connections.count, items: [...connections.items] };
            let modified = false;
            lnusers.forEach((lnuser) => {
                const index = newConnections.items.findIndex((item) => item.id === lnuser.id);
                if (index !== -1 && !utils.deepEqual(newConnections.items[index], lnuser)) {
                    newConnections.items[index] = lnuser;
                    modified = true;
                }
            });
            if (modified) {
                setConnections(newConnections);
            }
        }
        const selectedUsersFresh = getRecoil(selectedUsersState);
        if (selectedUsersFresh) {
            const newSelectedusers = [...selectedUsersFresh];
            lnusers.forEach((lnuser) => {
                const index = newSelectedusers.findIndex((item) => item.id === lnuser.id);
                if (index !== -1) {
                    newSelectedusers[index] = lnuser;
                }
            });
            setSelectedUsers(newSelectedusers);
        }
    }

    function listNotes(paramLnUser) {
        return fetchWrapper
            .get(`notes/lnusers/${paramLnUser?.id}`)
            .then((data) => {
                setNotes(data);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function updateInLeads(lnusers) {
        const leads = getRecoil(leadsState);
        if (leads) {
            const newLeads = { count: leads.count, items: [...leads.items] };
            lnusers.forEach((lnuser) => {
                const index = newLeads.items.findIndex((item) => item.id === lnuser.lead.id);
                if (index !== -1) {
                    const newLead = { ...newLeads.items[index] };
                    newLead.lnuser = lnuser;
                }
            });
            setLeads(newLeads);
        }
        const selectedUsersFresh = getRecoil(selectedUsersState);
        if (selectedUsersFresh) {
            const newSelectedusers = [...selectedUsersFresh];
            lnusers.forEach((lnuser) => {
                const index = newSelectedusers.findIndex((item) => item.id === lnuser.id);
                if (index !== -1) {
                    newSelectedusers[index] = lnuser;
                }
            });
            setSelectedUsers(newSelectedusers);
        }
    }

    function updateRemind(lnUserParam, date, updateLnUser) {
        setLoadingRemind(lnUserParam.id);
        return fetchWrapper
            .patch(`lnusers/${lnUserParam.id}`, { remind_at: date })
            .then((data) => {
                setLoadingRemind(false);
                if (!date) {
                    linkedInActions.getNotifs();
                }
                if (updateLnUser) {
                    setLnUser(data);
                }
                updateInConnections([data]);
                const filterConnections = getRecoil(filterConnectionsState);
                const connections = getRecoil(connectionsState);
                if (connections && filterConnections === 'reminders' && !date) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === lnUserParam.id);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => {
                setLoadingRemind(false);
                fetchNetworkError(null, e);
            });
    }

    function autocomplete(input, callback) {
        const limit = 10;
        return fetchWrapper
            .get(`lnusers?limit=${limit}&q=${input}`)
            .then((data) => {
                const result = data.items.map((item) => ({
                    value: item.id,
                    name: `${item.lead.firstname} ${item.lead.lastname}`,
                    headline: item.lead.headline,
                    picture: item.lead.picture,
                    is_connection: item.is_connection,
                    is_lead: item.is_lead,
                    is_sponsor: item.is_sponsor,
                }));
                callback(result);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function csv() {
        const filterConnections = getRecoil(filterConnectionsState);
        const filterName = getRecoil(filterNameState);
        const filterInbox = getRecoil(filterInboxState);
        const filterBoard = getRecoil(boardFilterState);
        const filterCompany = getRecoil(filterCompanyState);
        const filterJob = getRecoil(filterJobState);
        const filterLocation = getRecoil(filterLocationState);
        const filterHeadline = getRecoil(filterHeadlineState);
        const filterLabels = getRecoil(filterLabelsState);
        const filterNoLabel = getRecoil(filterNoLabelState);
        setCsvExport(true);
        const sort =
            filterConnections === 'archivedconv' || filterConnections === 'conversations' || filterConnections === 'all'
                ? 'sort=lastactivity&'
                : '';
        return fetchWrapper
            .download(
                `lnusers/csv?${sort}connections=${filterConnections}&q=${filterName}&inbox=${filterInbox}&board=${filterBoard}&company=${filterCompany}&job=${filterJob}&location=${filterLocation}&headline=${filterHeadline}&nolabel=${filterNoLabel}&labels=${filterLabels.join(
                    ','
                )}`,
                'user_export.csv'
            )
            .then(() => {
                setCsvExport(false);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function list(reset, update, offset) {
        const limit = 25;

        if (reset) {
            setConnections(null);
        }
        const filterConnections = getRecoil(filterConnectionsState);
        const filterName = getRecoil(filterNameState);
        const filterInbox = getRecoil(filterInboxState);
        const filterBoard = getRecoil(boardFilterState);
        const filterCompany = getRecoil(filterCompanyState);
        const filterJob = getRecoil(filterJobState);
        const filterLocation = getRecoil(filterLocationState);
        const filterHeadline = getRecoil(filterHeadlineState);
        const filterLabels = getRecoil(filterLabelsState);
        const filterNoLabel = getRecoil(filterNoLabelState);
        const sort =
            filterConnections === 'archivedconv' || filterConnections === 'conversations' || filterConnections === 'all'
                ? 'sort=lastactivity&'
                : '';
        setLoading(true);
        if (listController) {
            listController.abort();
        }
        listController = new AbortController();
        const fetchLnUsers = (resetList, updateConnections, nextOffset) => {
            const connections = getRecoil(connectionsState);
            const currentFilter = getRecoil(filterConnectionsState);
            let currentOffset = connections?.items?.length || 0;
            if (resetList) {
                currentOffset = 0;
            } else if (nextOffset !== undefined) {
                currentOffset = nextOffset;
            }
            if (!updateConnections && currentFilter !== filterConnections) {
                return;
            }

            fetchWrapper
                .get(
                    `lnusers?${sort}connections=${filterConnections}&limit=${limit}&offset=${currentOffset}&q=${filterName}&board=${filterBoard}&company=${filterCompany}&job=${filterJob}&location=${filterLocation}&inbox=${filterInbox}&headline=${filterHeadline}&nolabel=${filterNoLabel}&labels=${filterLabels.join(
                        ','
                    )}`,
                    null,
                    { signal: listController.signal }
                )
                .then((data) => {
                    if (updateConnections) {
                        updateInConnections(data?.items || []);
                    } else {
                        const currentItems = resetList ? [] : connections?.items || [];
                        const newConnections = { count: data.count, items: [...currentItems] };
                        data.items.forEach((lnuser) => {
                            const index = newConnections.items.findIndex((item) => item.id === lnuser.id);
                            if (index !== -1) {
                                newConnections.items[index] = lnuser;
                            } else {
                                newConnections.items.push(lnuser);
                            }
                        });
                        linkedInActions.refreshMe();
                        setConnections(newConnections);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    fetchNetworkError(null, e);
                    setLoading(false);
                });
        };
        const searchByKeywords =
            (filterConnections === 'archivedconv' || filterConnections === 'conversations') && filterName;
        if (sort) {
            const connections = getRecoil(connectionsState);
            let createdBefore;
            let nextOffset = reset ? 0 : connections?.items.length || 0;
            if (offset) {
                nextOffset = offset;
            }

            if (!reset && connections?.items && connections?.items.length > 0) {
                const lastConnection = connections?.items[connections?.items.length - 1];
                createdBefore =
                    moment(lastConnection.lastactivity_at || lastConnection.last_message?.last_message_at).valueOf() -
                    1;
            }
            let filterToApply = null;
            if (filterConnections === 'archivedconv') {
                filterToApply = 'archived';
            }
            if (filterInbox === 'unread') {
                filterToApply = 'unread';
            }
            // eslint-disable-next-line no-undef
            fetchWrapper
                .sendExtensionMessage(
                    {
                        conversations: true,
                        createdBefore,
                        accessToken: fetchWrapper.getAuth().access,
                        search: encodeURIComponent(filterName),
                        filter: filterToApply,
                    },
                    listController.signal
                )
                .then((data) => {
                    if (searchByKeywords) {
                        const currentItems = reset ? [] : connections?.items || [];
                        console.log('conv count', data.count);
                        const newConnections = { count: data.items.length, items: [...currentItems] };
                        data.items.forEach((lnuser) => {
                            const index = newConnections.items.findIndex((item) => item.id === lnuser.id);
                            if (index !== -1) {
                                newConnections.items[index] = lnuser;
                            } else {
                                newConnections.items.push(lnuser);
                            }
                        });
                        setConnections(newConnections);
                        setLoading(false);
                    } else {
                        const currentConnections = getRecoil(connectionsState);
                        if (currentConnections?.items.length === 0) {
                            const notifs = getRecoil(notifsState);
                            if (notifs.inbox > 0) {
                                linkedInActions.getNotifs();
                            }
                            fetchLnUsers(false, false, nextOffset);
                        } else if (nextOffset === 0) {
                            fetchLnUsers(false, true, nextOffset);
                        }
                    }
                })
                .catch(() => null);
        }
        if (!searchByKeywords) {
            fetchLnUsers(false, update, offset);
        }
    }

    function addboard(connection, boardId, stepId) {
        const paramBoard = boardId ? `board_id=${boardId}` : '';
        const paramStep = stepId ? `&step_id=${stepId}` : '';
        const board = getRecoil(boardState);
        const lnUser = getRecoil(lnUserState);
        const selectedLead = getRecoil(selectedLeadState);
        return fetchWrapper
            .post(`lnusers/connections/${connection.id}/addboard?${paramBoard}${paramStep}`)
            .then((data) => {
                if (!boardId) {
                    boardActions.getBoards();
                }
                if (board) {
                    setInitBoardLanes(false);
                    boardActions.getBoard(board.id);
                }
                updateInConnections([data]);
                if (lnUser && lnUser.id === connection.id) {
                    setLnUser(data);
                }
                if (selectedLead && selectedLead.id === data.lead.id) {
                    const newLead = { ...selectedLead };
                    newLead.lnUser = data;
                    setSelectedLead(newLead);
                }
                updateInLeads([data]);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function addLabels(connection, labels, add, removeAll) {
        let newLabels;
        if (add) {
            newLabels = connection.labels.map((label) => label.id);
            labels?.forEach((item) => {
                if (newLabels.indexOf(item) === -1) {
                    newLabels.push(item);
                }
            });
        } else {
            newLabels = [];
            if (!removeAll) {
                connection.labels.forEach((label) => {
                    if (labels.indexOf(label.id) === -1) {
                        newLabels.push(label.id);
                    }
                });
            }
        }
        return fetchWrapper
            .patch(`lnusers/${connection.id}`, { labels: newLabels })
            .then((data) => {
                updateInConnections([data]);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function bulkAddLabels(labels, selectedUsersParams, add, removeAll) {
        const bulkLabelProgress = getRecoil(bulkLabelProgressState);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        let index = 0;
        if (bulkLabelProgress === undefined) {
            setBulkLabelProgress(0);
        } else if (bulkLabelProgress === 'done') {
            return;
        } else if (bulkLabelProgress === usersSelected?.length - 1) {
            setBulkLabelProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkLabelProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkLabelProgress + 1;
            setBulkLabelProgress(bulkLabelProgress + 1);
        }
        const userToSend = usersSelected[index];
        addLabels(userToSend, labels, add, removeAll).then(() => {
            bulkAddLabels(labels, selectedUsersParams, add, removeAll);
        });
    }

    function enrich(selectedUsersParams) {
        setEnrichLoading(true);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        return fetchWrapper
            .post('lnusers/enrich', { ids: usersSelected.map((user) => user.id) })
            .then(() => {
                setEnrichLoading(false);
            })
            .catch((e) => {
                setEnrichLoading(false);
                fetchNetworkError(null, e);
            });
    }

    function moveLane(boardId, targetBoardId, selectedUsersParams, selectedLane) {
        setMoveLaneLoadingState(true);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        return fetchWrapper
            .post('lnusers/addboard', {
                ids: usersSelected.map((user) => user.id),
                board_id: targetBoardId,
                step_id: selectedLane.id,
            })
            .then(() => {
                setMoveLaneLoadingState(false);
                setInitBoardLanes(false);
                boardActions.getBoard(boardId);
            })
            .catch((e) => {
                setMoveLaneLoadingState(false);
                fetchNetworkError(null, e);
            });
    }

    function addLabelsLane(boardId, labels, selectedUsersParams) {
        setMoveLaneLoadingState(true);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        return fetchWrapper
            .post('lnusers/addlabels', {
                ids: usersSelected.map((user) => user.id),
                labels,
            })
            .then(() => {
                setMoveLaneLoadingState(false);
                setInitBoardLanes(false);
                boardActions.getBoard(boardId);
            })
            .catch((e) => {
                setMoveLaneLoadingState(false);
                fetchNetworkError(null, e);
            });
    }

    function bulkAddBoard(boardId, stepId, selectedUsersParams) {
        const bulkBoardProgress = getRecoil(bulkBoardProgressState);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        let index = 0;
        if (bulkBoardProgress === undefined) {
            setBulkBoardProgress(0);
        } else if (bulkBoardProgress === 'done') {
            return;
        } else if (bulkBoardProgress === usersSelected?.length - 1) {
            setBulkBoardProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkBoardProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkBoardProgress + 1;
            setBulkBoardProgress(bulkBoardProgress + 1);
        }
        const userToSend = usersSelected[index];
        addboard(userToSend, boardId, stepId).then(() => {
            bulkAddBoard(boardId, stepId, selectedUsersParams);
        });
    }

    function removeboard(connection) {
        return fetchWrapper
            .post(`lnusers/connections/${connection.id}/removeboard`)
            .then((data) => {
                const connections = getRecoil(connectionsState);
                const lnUser = getRecoil(lnUserState);
                const selectedLead = getRecoil(selectedLeadState);
                if (connections) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === connection.id);
                    if (index !== -1 && !utils.deepEqual(newConnections.items[index], data)) {
                        newConnections.items[index] = data;
                        setConnections(newConnections);
                    }
                }
                if (lnUser && lnUser.id === connection.id) {
                    setLnUser(data);
                }
                if (selectedLead && selectedLead.id === data.lead.id) {
                    const newLead = { ...selectedLead };
                    newLead.lnuser = data;
                    setSelectedLead(newLead);
                }
                const board = getRecoil(boardState);
                if (board) {
                    const newBoard = JSON.parse(JSON.stringify(board));
                    newBoard.lanes?.forEach((lane) => {
                        const index = lane.cards?.findIndex((item) => item.id === connection.id);
                        if (index !== -1) {
                            lane.cards.splice(index, 1);
                            setBoard(newBoard);
                        }
                    });
                }
                updateInLeads([data]);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function bulkRemoveBoard(selectedUsersParams) {
        const bulkBoardProgress = getRecoil(bulkBoardProgressState);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        let index = 0;
        if (bulkBoardProgress === undefined) {
            setBulkBoardProgress(0);
        } else if (bulkBoardProgress === 'done') {
            return;
        } else if (bulkBoardProgress === usersSelected?.length - 1) {
            setBulkBoardProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkBoardProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkBoardProgress + 1;
            setBulkBoardProgress(bulkBoardProgress + 1);
        }
        const userToSend = usersSelected[index];
        removeboard(userToSend).then(() => {
            bulkRemoveBoard(selectedUsersParams);
        });
    }

    function bulkRemind(selectedUsersParams, remind) {
        let remindDate = null;
        if (remind.type && remind.type !== 'remove') {
            const { date } = remind;
            remindDate = date;
            if (remind.type === '1day') {
                remindDate = moment().add(1, 'days').startOf('day');
            } else if (remind.type === '3days') {
                remindDate = moment().add(3, 'days').startOf('day');
            } else if (remind.type === '1week') {
                remindDate = moment().add(7, 'days').startOf('day');
            }
        }
        const bulkRemindProgress = getRecoil(bulkRemindProgressState);
        const selectedUsers = getRecoil(selectedUsersState);
        const usersSelected = selectedUsersParams || selectedUsers;
        let index = 0;
        if (bulkRemindProgress === undefined) {
            setBulkRemindProgress(0);
        } else if (bulkRemindProgress === 'done') {
            return;
        } else if (bulkRemindProgress === usersSelected?.length - 1) {
            setBulkRemindProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkRemindProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkRemindProgress + 1;
            setBulkRemindProgress(bulkRemindProgress + 1);
        }
        const userToSend = usersSelected[index];
        updateRemind(userToSend, remindDate, false).then(() => {
            bulkRemind(selectedUsersParams, remind);
        });
    }

    function processLead(userId) {
        setLnUserUpdateConnectionLoading('makeprocessed');
        return fetchWrapper
            .post(`lnusers/${userId}/process`)
            .then((data) => {
                setLnUserUpdateConnectionLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                    setLnUser(data);
                }
                const connections = getRecoil(connectionsState);
                const filterConnectionsCurent = getRecoil(filterConnectionsState);
                if (connections && filterConnectionsCurent !== 'processed') {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => {
                setLnUserUpdateConnectionLoading(false);
                fetchNetworkError(null, e);
            });
    }

    function unprocessLead(userId) {
        setLnUserUpdateConnectionLoading('makeprocessed');
        return fetchWrapper
            .post(`lnusers/${userId}/unprocess`)
            .then((data) => {
                setLnUserUpdateConnectionLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                    setLnUser(data);
                }
                const connections = getRecoil(connectionsState);
                const filterConnectionsCurent = getRecoil(filterConnectionsState);
                if (connections && filterConnectionsCurent === 'processed') {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => {
                setLnUserUpdateConnectionLoading(false);
                fetchNetworkError(null, e);
            });
    }

    function moveToLeads(userId) {
        setLnUserUpdateConnectionLoading('makelead');
        return fetchWrapper
            .post(`lnusers/${userId}/movetoleads`)
            .then((data) => {
                setLnUserUpdateConnectionLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                    setLnUser(data);
                }
                const connections = getRecoil(connectionsState);
                const filterConnectionsCurrent = getRecoil(filterConnectionsState);
                if (
                    connections &&
                    (filterConnectionsCurrent === 'connections' || filterConnectionsCurrent === 'contacts')
                ) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => {
                setLnUserUpdateConnectionLoading(false);
                fetchNetworkError(null, e);
            });
    }

    function removeFromLeads(userId) {
        setLnUserUpdateConnectionLoading('makelead');
        return fetchWrapper
            .post(`lnusers/${userId}/removefromleads`)
            .then((data) => {
                setLnUserUpdateConnectionLoading(false);
                const currentLnUser = getRecoil(lnUserState);
                if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                    setLnUser(data);
                }
                const filterConnectionsCurrent = getRecoil(filterConnectionsState);
                const connections = getRecoil(connectionsState);
                if (
                    connections &&
                    (filterConnectionsCurrent === 'connections' ||
                        filterConnectionsCurrent === 'contacts' ||
                        filterConnectionsCurrent === 'processed')
                ) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => {
                setLnUserUpdateConnectionLoading(false);
                fetchNetworkError(null, e);
            });
    }

    function hideUser(userId, hide) {
        return fetchWrapper
            .post(`lnusers/${userId}/${hide ? 'archive' : 'unarchive'}`)
            .then((data) => {
                const connections = getRecoil(connectionsState);
                const lnUser = getRecoil(lnUserState);
                if (connections) {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
                if (lnUser && lnUser.id === userId) {
                    setLnUser(data);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function bulkHide(hide) {
        const bulkHideProgress = getRecoil(bulkHideProgressState);
        let index = 0;
        const selectedUsers = getRecoil(selectedUsersState);
        if (bulkHideProgress === undefined) {
            setBulkHideProgress(0);
        } else if (bulkHideProgress === 'done') {
            return;
        } else if (bulkHideProgress === selectedUsers?.length - 1) {
            setBulkHideProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkHideProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkHideProgress + 1;
            setBulkHideProgress(bulkHideProgress + 1);
        }
        const userToSend = selectedUsers[index];
        hideUser(userToSend.id, hide).then(() => {
            bulkHide(hide);
        });
    }

    function bulkMove(move) {
        const bulkMoveProgress = getRecoil(bulkMoveProgressState);
        let index = 0;
        const selectedUsers = getRecoil(selectedUsersState);
        if (bulkMoveProgress === undefined) {
            setBulkMoveProgress(0);
        } else if (bulkMoveProgress === 'done') {
            return;
        } else if (bulkMoveProgress === selectedUsers?.length - 1) {
            setBulkHideProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkMoveProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkMoveProgress + 1;
            setBulkMoveProgress(bulkMoveProgress + 1);
        }
        const userToSend = selectedUsers[index];
        if (move) {
            moveToLeads(userToSend.id).then(() => {
                bulkMove(move);
            });
        } else {
            removeFromLeads(userToSend.id).then(() => {
                bulkMove(move);
            });
        }
    }

    function bulkProcess(process) {
        const bulkProcessLeadsProgress = getRecoil(bulkProcessLeadsProgressState);
        let index = 0;
        const selectedUsers = getRecoil(selectedUsersState);
        if (bulkProcessLeadsProgress === undefined) {
            setBulkProcessLeadsProgress(0);
        } else if (bulkProcessLeadsProgress === 'done') {
            return;
        } else if (bulkProcessLeadsProgress === selectedUsers?.length - 1) {
            setBulkProcessLeadsProgress('done');
            setTimeout(() => {
                setSelectedUsers([]);
                setBulkProcessLeadsProgress(undefined);
            }, 1000);
            return;
        } else {
            index = bulkProcessLeadsProgress + 1;
            setBulkProcessLeadsProgress(bulkProcessLeadsProgress + 1);
        }
        const userToSend = selectedUsers[index];
        if (process) {
            processLead(userToSend.id).then(() => {
                bulkProcess(process);
            });
        } else {
            unprocessLead(userToSend.id).then(() => {
                bulkProcess(process);
            });
        }
    }

    function download() {
        return fetchWrapper.download('lnusers/connections/csv');
    }
    return {
        csv,
        updateRemind,
        listNotes,
        moveLane,
        hideUser,
        bulkHide,
        bulkRemind,
        bulkMove,
        bulkProcess,
        processLead,
        unprocessLead,
        moveToLeads,
        removeFromLeads,
        enrich,
        addLabels,
        bulkAddLabels,
        bulkRemoveBoard,
        bulkAddBoard,
        autocomplete,
        addboard,
        removeboard,
        syncConnections,
        list,
        download,
        addLabelsLane,
    };
}
